export const menuItemsTableName = 'menuItems';
export const restaurantsTableName = 'restaurants';
export const associatesTableName = 'associates';
export const menuDaysTableName = 'menuDays';
export const entertainmentItemsTableName = 'entertainmentItems';
export const lambdaFunctionURL = 'https://kd7snpev85.execute-api.us-east-1.amazonaws.com/default/i_want_to_dine_api';
export const apiName = 'i_want_to_dine_restaurant_api';
export const apiNameNoToken = 'i_want_to_dine_api';
export const apiPath = '/';
export const projectionExpressionRestaurant = 'id,restaurantName,approved,city,street,zipCode,menuItemIdsJSON,entertainmentItemIdsJSON,associatesJSON,menuDayIdsJSON,stateUS,urlLink,description,phoneNumber';
export const projectionExpressionMenuItem = 'title,description,categoryJSON,price,id,restaurant';
export const projectionExpressionMenuDay = 'id,title,dateFrom,dateTo,description,menuItemIdsJSON,entertainmentItemIdsJSON,associatesJSON';
export const projectionExpressionAssociate = 'id,firstName,lastName,bio,jobTitle,email,restaurantIdsJSON,imageUrl';
export const projectionExpressionEntertainmentItem = 'id,title,timeFrom,timeTo,description,imageUrl,categoryJSON,restaurantId';
export const noSelectedRestaurant = 'Select Your Restaurant';
export const blankPlaceHolder = String.fromCharCode(30);
export const imagePath = 'https://iwanttodine.s3.amazonaws.com/public/';
export const blankImage = 'https://iwanttodine.s3.amazonaws.com/public/blank.jpg';